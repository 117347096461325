import {createApp} from '../../node_modules/vue/dist/vue.esm-bundler';

//components
import Articles from './components/Articles.vue';


//modules
import collapsible from './modules/Collapsible';
import _switch from './modules/Switch';
import animate from './modules/Animate';
import navbar from './modules/Navbar';
import slider from './modules/Slider';
import modal from './modules/Modal';
import form from './modules/Form';
import customEvents from './modules/CustomEvents';
import parallax from './modules/Parallax';

import scrollytellingBars from './scrollytelling/ScrollytellingBars';
import scrollytellingCta from './scrollytelling/ScrollytellingCta';
import scrollytellingToggle from './scrollytelling/ScrollytellingToggle';


collapsible.init();
_switch.init();
animate.init();
navbar.init();
slider.init();
modal.init();
form.init();
customEvents.init();

// init scrollytelling elements
scrollytellingBars.init();
scrollytellingCta.init();
scrollytellingToggle.init();

//init parallax Header
parallax.initHeader('.hero', '.hero img', '.hero-content');

const articlesContainer = document.querySelector('.vue-articles');
if (articlesContainer) {
    const articlesApp = createApp({});
    articlesApp.component('articles', Articles);
    articlesApp.mount('.vue-articles');
}



//sticky eyebrow text in cases
const stickySection = document.querySelector('.js-sticky-section');
const stickyContent = document.querySelector('.js-sticky-content');

const options = {
  root: null,
  rootMargin: '-70px 0px 0px 0px',
  threshold: 1
};

// Speichert die vorherigen Werte für jedes Element
let elementsState = {};

const observer = new IntersectionObserver((entries, observer) => {
  entries.forEach(entry => {
    const elementId = entry.target.id;

    // Initialisiere den Zustand 
    if (!elementsState[elementId]) {
      elementsState[elementId] = { previousY: 0, previousRatio: 0 };
    }

    const currentY = entry.boundingClientRect.y;
    const currentRatio = entry.intersectionRatio;
    const isIntersecting = entry.isIntersecting;

    //scrolling down
    if (currentY < elementsState[elementId].previousY) {
      if (currentRatio > elementsState[elementId].previousRatio && isIntersecting) { 
        /* console.log("Scrolling down enter"); */
      } else {
        stickyContent.textContent = entry.target.innerText;
        stickySection.classList.add('is-active');
        /* console.log("Scrolling down leave"); */
      }
    }
    //scrolling up
    else if (currentY > elementsState[elementId].previousY && isIntersecting) {
      if (currentRatio < elementsState[elementId].previousRatio) {
        /* console.log("Scrolling up leave"); */
      } else {
        let curIndex = eyebrows.indexOf(entry.target);
        const prevIndex = curIndex - 1;
        if (curIndex == 0) {
          stickySection.classList.remove('is-active');
        } else {
          stickyContent.textContent = eyebrows[prevIndex].innerText;
          stickySection.classList.add('is-active');
        }
        /* console.log("Scrolling up enter"); */
      }
    }

    // Aktualisiere den Zustand 
    elementsState[elementId].previousY = currentY;
    elementsState[elementId].previousRatio = currentRatio;
  });
}, options);

const eyebrows = Array.from(document.querySelectorAll('.js-article-content .js-eyebrow'));

function setSectionTrackingId(eyebrow, trackingSections) {
    let eyebrowText = eyebrow.innerText.toLowerCase();
    if (trackingSections.has(eyebrowText)) {
        const section = eyebrow.closest('section')
        section.id = trackingSections.get(eyebrowText);
    }
}

const trackingSections = new Map([
    ['aufgabe', 'task-section'],
    ['lösung', 'solution-section'],
    ['ergebnis', 'outcome-section'],
    ['task', 'task-section'],
    ['solution', 'solution-section'],
    ['result', 'outcome-section'],
])

if (eyebrows.length && stickyContent) {
  eyebrows.forEach(eyebrow => {
    // if tracking relevant, set tracking id on section
    setSectionTrackingId(eyebrow, trackingSections);
    // set id on eyebrow for observer
    if (!eyebrow.id) {
      eyebrow.id = 'unique-id-' + Math.random().toString(36).substr(2, 9);
    }
    observer.observe(eyebrow);
  });
}

// loading circle for all picture elements inside .loader-js parent
const loaders = document.querySelectorAll('.loader-js');

if (loaders.length) {
  loaders.forEach(loader => {
    const pictures = loader.querySelectorAll('picture');
    pictures.forEach(picture => {
      let img = picture.querySelector('img');
      if (!img.complete) {
        picture.classList.add('is-element-loading');
      }
      img.onload = () => {
        picture.classList.remove('is-element-loading');
      };
    });
  });
}


